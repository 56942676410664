


































import Vue from "vue";
import { mapState } from "vuex";
import PhotoTextBlock from "@/components/blocks/PhotoTextBlock.vue";
import projectList from "@/assets/contents/ProjectContent";

export default Vue.extend({
    name: "ProjectSection",
    components: {
        PhotoTextBlock,
    },
    props: {
    },
    data() {
        return {
            sectionTitle: "Projects",
            projectList,
            hideBullet: true,
        };
    },
    computed: mapState([
        "oneColumnGrid",
    ]),
});
