export default [
    {
        title: "Phototile App",
        role: "Software Engineer",
        startDate: "Oct 2019",
        endDate: "Jun 2020",
        image: "",
        linkURL: "",
        descriptions: [
            "Enhanced user privacy and security by developing a token-based authentication system with PHP and MySQL",
            "Reduced image download time by 50% by implementing a new compression algorithm based on OpenCV",
            "Reduced the time user spent on importing photo into APP by integrating Google, Facebook and Instagram Photo APIs based on OAuth2 authorization",
        ],
    },
];
